
<template>
<div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header bg-white">
          <b-form-group label="Filter by Status" class="mb-2" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              id="radio-group-2"
              v-model="defaultStatus"
              :aria-describedby="ariaDescribedby"
              name="radio-default-status">
              <b-form-radio  value="active">Active</b-form-radio>
              <b-form-radio  value="progress">Progress</b-form-radio>
              <b-form-radio  value="completed">Completed</b-form-radio>
              <b-form-radio  value="cancelled">Cancelled</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-sm-12 col-md-8">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex mr-2 align-items-center">
                  <b-form-select v-model="itemsPerPage" :options="pageOptions"></b-form-select>
                </label>
                <b-dropdown right variant="light">
                  <template slot="button-content">
                    <i class="mdi mdi-filter-variant"></i>
                  </template>
                  <b-dropdown-item-button @click="selectAllRows()">
                    <i :class="isSelectAll? 'mdi mdi-playlist-remove' : 'mdi mdi-playlist-check'" ></i> {{isSelectAll? 'Invert selection' : 'Select all' }}
                  </b-dropdown-item-button>
                </b-dropdown>
              </div>
            </div>
            <div class="col-sm-12 col-md-4">
              <div class="">
                <label class="d-block">
                  <b-form-input v-model="searchQuery" @input="updateSearchQuery" type="search" placeholder="Search..." class="form-control"></b-form-input>
                </label>
              </div>
            </div>
          </div>
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover table-class="table font-sm table-centered w-100" thead-tr-class=""
              ref="selectableTable" :items="orders" :fields="columns" responsive="sm">
              <template v-slot:cell(number)="data">
                <p @click="navigateTo(data.item.id)" class="m-0 d-inline-block cursor-pointer align-middle">
                  <span class="text-dark font-sm">#{{ data.item.number }} </span>
                </p>
              </template>
              <template v-slot:cell(buyer)="data">
                <p @click="navigateTo(data.item.id)" class="m-0 d-inline-block cursor-pointer align-middle" v-if="data.item.buyer">
                  <span class="text-dark font-sm">{{ data.item.buyer.first_name }} {{ data.item.buyer.last_name }}</span>
                </p>
              </template>
              <template v-slot:cell(item_title) = "data">
                <div style="min-width:5rem;">
                  {{ data.item.type == "TASK" ? data.item.task.name : data.item.product.name }}
                </div>
              </template>
              <template v-slot:cell(date)="data">
                <div style="min-width:5rem;" @click="navigateTo(data.item.id)" class="mb-0 cursor-pointer">
                  <span class="d-block">{{ data.item.created_at | date_time(1) }} </span>
                </div>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="paging_simple_numbers float-right">
                <b-pagination v-model="currentPage" class="pagination-rounded mb-0"
                  :total-rows="pageData.total" :limit="3" :per-page="itemsPerPage"></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>

import IsLoading from '../IsLoading.vue';
import _ from 'lodash';

export default {
  components:{
    IsLoading
  },
  name:"order-list-table",
  data() {
    return {
      isLoading: true,
      defaultStatus:"active",
      searchQuery:"",
      pageOptions: [10, 25, 50, 100],
      selected: [],
      isSelectAll: false,
      columns: [
          {
              key: "number",
              label: "Number",
              sortable: true
          },
          {
              key: "buyer",
              sortable: true
          },
          {
              key: "item_title",
              sortable: true
          },
          {
              key: "date",
              label: "Date",
          }
      ],
    }
  },
  watch: {
    "$route.query":"refreshQueryStatus",
    defaultStatus (val) {
      this.fetchOrders()
    }
  },
  computed: {
    pageData(){
      return this.$store.state.orderList.pageData
    },
    orders() {
      return this.pageData.data.filter((order) => order.status == this.defaultStatus)
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchOrders(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchOrders(1, val)
      }
    },
  },
  methods: {
    navigateTo(id){
      this.$router.push({path:`/orders/${id}`});
    },
    refreshQueryStatus(){
        if(this.$route.query.status){
          this.defaultStatus = this.$route.query.status
        }
    },
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.orders.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchOrders()
    }, 2000),
    fetchOrders(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.defaultStatus != 'all'){ payload.status = this.defaultStatus }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$store.dispatch("orderList/fetchOrders", payload)
      .then(response => this.isLoading = false)
    },
  },
  mounted() {
    this.refreshQueryStatus()
    if(this.defaultStatus == "active"){this.fetchOrders()}
  },
}

</script>

<style lang="scss">
.font-sm{
  font-size:13px;
}
</style>

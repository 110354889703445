<template>
    <div class="order-list">
        <div class="row">
            <div class="col-lg-12">
                <order-list-table />
            </div>
        </div>
    </div>
</template>
<script>

import OrderListTable from "@/components/order/OrderListTable.vue"

export default {
    name: 'order-list',
    components:{
        OrderListTable
    },
}
</script>
